.main {
  margin-left: 15%;
  --side-panel: #27272a;
  /* --side-panel: #d9f6ff; */
  --outbound-texts: #f19a36;
  --unread: rgb(97, 205, 255);
  --background: #3f3f46;
  --contacts: #e7e7e7;
}

.sms-form textarea {
  font-size: 19px;
  width: 800px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#inbound {
  color:#ffffff;
  text-align: left;
  background-color: gray;
  margin-right: auto;
  margin-left: 3%;
  justify-content: flex-end;
  align-items: flex-end;
  order: 1;
  border-bottom-left-radius: 0px;
  box-shadow: 1px 2px 0px #d4d4d4;
  max-width: 65%;
}

#outbound {
  color: white;
  background-color: var(--outbound-texts);
  text-align: right;
  margin-left: auto;
  margin-right: 3%;
  justify-content: flex-end;
  align-items: flex-end;
  order: 1;
  border-bottom-right-radius: 0px;
  box-shadow: 1px 2px 0px #d4d4d4;
  max-width: 65%;
}

#undelivered {
  background-color: rgb(231, 90, 90);
  color: white;
  text-align: right;
  margin-left: auto;
  margin-right: 3%;
  justify-content: flex-end;
  align-items: flex-end;
  order: 1;
  border-bottom-right-radius: 0px;
  box-shadow: 1px 2px 0px #d4d4d4;
  max-width: 65%;
}

.text {
  padding:10px;
  margin-right:5%;
  margin-left:5%;
  /* white-space:pre; */
  font-size: 19px;
  /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
  border-radius: 15px;
  width: fit-content;
  overflow-wrap: anywhere;
}

.form {
  margin-right:10%;
  margin-left:10%;
}

#scroll {
  color: rgb(255, 255, 255);
  background-color: var(--side-panel);
  width: 350px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
}

#scroll::-webkit-scrollbar {
  display: none;
}

.click {
  background-color: var(--contacts);
  font-size: 16px;
  width: 320px;
  height: 68px;
  margin-top:10px;
  /* overflow-wrap: break-word;
  overflow-y: hidden; */
  hyphens: auto;
  /* text-overflow: ellipsis; */
}

.click p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.unread {
  background-color: rgb(97, 205, 255);
}

#email {
  position: fixed;
  bottom:5px;
  left:5px;
  padding: 10px;
  font-size: 18px;
  background-color: var(--side-panel);
  color:rgb(255, 255, 255);
  width: 260px;
  text-overflow: ellipsis;
  overflow:hidden;
  white-space:nowrap;
  z-index: 1;
}

#namebar {
  position: fixed;
  bottom:0px;
  left:0px;
  background-color: var(--side-panel);
  color: var(--side-panel);
  width: 400px;
  height: 55px;
}

input, textarea {
  border-radius: 15px;
  width: 180px;
  height: 35px;
  font-size: 1em;
}

#out {
  margin-left: 390px;
  margin-right: auto;
  position: fixed;
  top: 0px;
  left: 0;
  background-color: var(--background);
  border: 2px solid #15171b;
  width: 80%;
}

#to_div {
  padding: 1rem;
  text-align: left;
}

.innerto {
  display: inline-block;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  vertical-align: left;
}

.innerto input {
  width: max-content;
}

.bottombar {
  margin-left: 390px;
  margin-right: auto;
  position: fixed;
  bottom: 0px;
  left: 0;
  background-color: var(--background);
  border: 2px solid #15171b;
  width: 80%;
  overflow: hidden;
  /* justify-content: space-evenly; */
}

.message {
  display: inline-block;
  padding: 0.3rem;
  margin: 1rem;
  text-align: left;
  min-height: 35px;
  max-height: 250px;
  width: 77%;
  /* border: 2px solid #15171b; */
  border-radius: 15px;
  background-color: whitesmoke;
  color: black;
  overflow-y: scroll;
}

.unfocused {
  color:#707070;
  border: 4px solid var(--background);
}

.focused {
  border: 4px solid var(--outbound-texts);
}

.send {
  display: inline-block;
  padding: 0.5rem;
  vertical-align: right;
  height: 50px;
  width: 75px;
  position: fixed;
  right: 23px;
  bottom: 24px;
  box-shadow: 1px 1px 1px 0 lightgray inset;  
}

.sms-form textarea {
  font-size: 1em;
  width: 900px;
  float: right;
  /* box-sizing: border-box; */
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.messages-title {
  color: rgb(255, 255, 255);
  display: inline-block;
  align-items: center;
  margin-right: 30px;
  margin-left: 90px;
}

.btn {
  display: inline-block;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  /* padding: 12px 16px; */
  font-size: 16px;
  cursor: pointer;
  margin-left: 20px;
  margin-bottom: 15px;
  width: 40px;
  height: 40px;
  border-color: var(--outbound-texts);
  background-color: var(--contacts);
}

/* Darker background on mouse-over */
.btn:hover {
  background-color: #7d7d7d;
}

.notediting input {
  background-color: var(--background);
  color: white;
  /* border: none; */
  border: 0px;
}

#info {
  position: fixed;
  top: 0;
  right: 0;
  margin-top: 25px;
  margin-right: 30px;
  width: 40px;
  height: 40px;
  z-index: 5;
  border-radius: 30px;
  border-color: var(--outbound-texts);
  background-color: var(--contacts);
  color: black;
}

.selected {
  /* margin-left:2rem; */
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#date {
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-size: 20px;
  color:#b4b4b4
}

.smalltime {
  color: #b4b4b4;
  margin-right:3%;
  margin-left:5%;
  margin-top: 0.5%;
  /* white-space:pre; */
  font-size: 15px;
  /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
  margin-bottom: 0.5rem;
}

.notice {
  color: #a56c6c;
  margin-right:3%;
  margin-left:5%;
  margin-top: 0.5%;
  /* white-space:pre; */
  font-size: 12px;
  /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
  margin-bottom: 0.5rem;
  text-align: right;
}

#intime {
  text-align: left;
  margin-left: 3%;
}

#outtime {
  text-align: right;
}