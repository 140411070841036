.App {
  font-size: calc(10px + 2vmin);
  margin: -10px;
}

.App-header {
  background-color: #3f3f46;
  /* background-color: #282c34; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: calc(100vh - 15px);
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.App::-webkit-scrollbar {
  display: none;
}

.bgimage {
  background-image:
    url("/chat_bg.jpg");
  background-size: "cover";

  background-size: contain;

  /*margin-top: -85px;
  
  background-repeat:no-repeat;*/
}

.loginbox {

  background-image: url("/voip_logo.png");
  background-repeat: no-repeat;
  background-position: center;
}

.login {
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  font-size: xx-large;
  background-clip: border-box;
}

h7 {
  font-family: ui-rounded;
  font-size: xxx-large;
  color: #132748;
}

button {
  background-color: #d8dbdf;
  width: 200px;
  height: 60px;
  margin: auto;
  font-size: medium;
  border-radius: 15px;
  cursor: pointer;
}

#logout {
  display: inline-block;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  vertical-align: right;
  height: 40px;
  width: 90px;
  bottom: 7px;
  left: 285px;
  position: fixed;
  z-index: 1;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
  filter: alpha(opacity=60);
  -webkit-box-shadow: none;
  box-shadow: none;
}