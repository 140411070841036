.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  width: 25%;
  padding: 5px;
  font-size: 20px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.popup-arrow {
  color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
  width: 100px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.popup {
  font-size: 20px;
}

#form {
  text-align: center;
}

#form button {
  width:fit-content;
  height:fit-content;
}

.number {
  font-size: 25px;
  margin: 0.5rem;
}

img {
  border-radius: 100px;
}

.close {
  margin-left: 5px;
  cursor: pointer;
}